import React from 'react'
import { graphql } from 'gatsby'

import Layout from "../../src/components/layout"
import SEO from "../../src/components/seo"

import Light from '../../src/components/hero/Light'


const PageTemplate = pageProps => {
  let data

data = pageProps.data.wpPage

  const heroComponentsArray = data.hero.hero || []
  const pageComponentsArray = data.components.components || []
  const heroComponents = heroComponentsArray.map(component => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
    }
  })
  const pageComponents = pageComponentsArray.map(component => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
    }
  })
  const invertHeader = data.page_options.invertHeader
  const invertPage = data.page_options.invertPage
  const hideCta = data.page_options.hideCta

  return (
    <Layout
      invertHeader={invertHeader || false}
      invertPage={invertPage || false}
      hideCta={hideCta || false}>
      <SEO
        title={data.seo.title}
        description={data.seo.metaDesc}
        imageURL={data.seo.opengraphImage.localFile.publicURL}
        index={data.seo.metaRobotsNoindex}
        follow={data.seo.metaRobotsNofollow} />
      {heroComponents.map((component, index) => {

	  if (component.name == 'Light') {
		return <Light {...component.data} title={data.title} key={index} />
	  }
	
        return <div>Error: The component {component.name} was not found</div>
      })}
      {data.content && (
        <section className="page-content">
          <div className="container">
            <div className="page-content__content margin-fix" dangerouslySetInnerHTML={{__html:data.content}}></div>
          </div>
        </section>
      )}
      {pageComponents.map((component, index) => {

        return <div>Error: The component {component.name} was not found</div>
      })}
    </Layout>
  )
}

export default PageTemplate


	export const query = graphql`
	  query PageQuery7469($id: String!) {
		wpPage(id: {eq: $id}) {
		  title
      content
		  page_options {
			  invertHeader
        invertPage
        hideCta
			}
			seo {
				title
				metaDesc
				metaRobotsNoindex
        metaRobotsNofollow
        opengraphImage {
          localFile {
            publicURL
          }
        }
			}
		  
	hero {
	  hero {
		__typename
		 
 
	  ... on WpPage_Hero_Hero_Full {
		
		heading
    content
	
	  }
	 
 
	  ... on WpPage_Hero_Hero_Homepage {
		
		heading
		preHeading
		subHeading
    buttons {
      button {
        target
        title
        url
      }
    }
	
	  }
	 
 
	  ... on WpPage_Hero_Hero_Light {
		
		heading
		content
		centered
    background
    formId
    formHeading
	
	  }
	 
 
	  ... on WpPage_Hero_Hero_LightWithGraphic {
		
		heading
		content
		ctaLink {
			title
			url
			target
		}
		graphic {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: TRACED_SVG
						outputPixelDensities: [1.5, 2]
						formats: [AUTO, WEBP]
						transformOptions: {cropFocus: CENTER, fit: CONTAIN}
					)
				}
			}
		}
	
	  }
	
	  }
	}
	components {
		components {
		__typename
		 
 
		... on WpPage_Components_Components_CalendlyEmbed {
		
		url
	
		}
	 
 
		... on WpPage_Components_Components_Callout {
		
		sectionLabel
		heading
		content
		buttons {
			button {
				target
				title
				url
			}
		}
    leftWithGraphic
    animation {
      localFile {
        absolutePath
        base
        publicURL
        relativePath
      }
    }
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_CardGrid {
		
		sectionLabel
		heading
		content
		cards {
			cardLink {
				target
				title
				url
			}
			cardTitle
			cardImage {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							height: 120
							quality: 100
							placeholder: TRACED_SVG
              outputPixelDensities: [1.5, 2]
							formats: [AUTO, WEBP]
						)
					}
				}
			}
			cardContent
			cardColor
		}
	
		}
	 
 
		... on WpPage_Components_Components_CardSlider {
		
		sectionLabel
		heading
    animation {
      localFile {
        absolutePath
        base
        publicURL
        relativePath
      }
    }
    graphic {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            quality: 80
            formats: [AUTO, WEBP]
            outputPixelDensities: [1.5, 2]
            width: 972
            height: 659
            transformOptions: {cropFocus: NORTH}
          )
        }
      }
    }
		cards {
			heading
			subheading
			content
			color
		}
    background {
      hasBackground
      squiggleTop
      squiggleBottom
    }
	
		}
	 
 
		... on WpPage_Components_Components_ChartsStats {
		
    sectionLabel
    heading
    content
    charts {
      title
      chart {
        gatsbyImage(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [AUTO, WEBP]
          outputPixelDensities: [1.5, 2]
          quality: 100
          width: 1170
        )
      }
    }
    stats {
      heading
      dataPoints {
        icon {
          gatsbyImage(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            outputPixelDensities: [1.5, 2]
            quality: 100
            width: 60
          )
          localFile {
            publicURL
            extension
          }
        }
        data
        label
      }
    }
    background {
      hasBackground
      lastComponent
      squiggleBottom
      squiggleTop
    }
	
		}
	 
 
		... on WpPage_Components_Components_ContactForm {
		
		heading
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_DataSlider {
		
		dataSlides {
			slideContent
			slideDataPrefix
			slideData
			slideDataSuffix
		}
	
		}
	 
 
		... on WpPage_Components_Components_FeaturedBlogGrid {
		
		latestTitle
		mostPopularTitle
		mostPopularPosts {
          ... on WpPost {
            title
            uri
		        categories {
              nodes {
                name
                categoryOptions {
                  color
                }
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: TRACED_SVG
                      quality: 80
                      formats: [AUTO, WEBP]
                      outputPixelDensities: [1.5, 2]
                      width: 140
                      height: 100
                      transformOptions: {cropFocus: NORTH}
                    )
                  }
                }
              }
            }
          }
        }
	
		}
	 
 
		... on WpPage_Components_Components_FullBlogGrid {
		
		fullBlogTitle
	
		}
	 
 
		... on WpPage_Components_Components_GravityForm {
		
    sectionLabel
    heading
    content
    formId
    background {
      hasBackground
      lastComponent
      squiggleBottom
      squiggleTop
    }
    backgroundImage {
      gatsbyImage(
        placeholder: BLURRED
        layout: FULL_WIDTH
        formats: [AUTO, WEBP]
        outputPixelDensities: [1.5, 2]
        quality: 80
        width: 1920
      )
    }
	
		}
	 
 
		... on WpPage_Components_Components_IconBoxes {
		
		tag
		heading
		iconBox {
			boxText
			boxIcon {
				localFile {
					childImageSharp {
						gatsbyImageData(
              layout: CONSTRAINED
              placeholder: TRACED_SVG
              quality: 80
              formats: [AUTO, WEBP]
              outputPixelDensities: [1.5, 2]
              width: 40
              height: 40
              transformOptions: {cropFocus: NORTH}
						)
					}
				}
			}
		}
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_IconList {
		
		sectionLabel
		heading
		content
		link {
			target
			title
			url
		}
		listItems {
			itemText
			itemIcon {
				localFile {
					childImageSharp {
						gatsbyImageData(
              layout: CONSTRAINED
              placeholder: TRACED_SVG
              quality: 80
              formats: [AUTO, WEBP]
              outputPixelDensities: [1.5, 2]
              width: 40
              height: 40
						)
					}
				}
			}
		}
	
		}
	 
 
		... on WpPage_Components_Components_IconListExtended {
		
    tag
		heading
    content
		listItems {
			itemText
		}
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_JobList {
		
		sectionLabel
		heading
		content
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_KeyPoints {
		
    sectionLabel
    heading
    content
    background {
      hasBackground
      lastComponent
      squiggleBottom
      squiggleTop
    }
    keyPoints {
      itemTitle
      itemContent
    }
    link {
      title
      url
      target
    }
	
		}
	 
 
		... on WpPage_Components_Components_LargeGraphicCenteredText {
		
    tag
		heading
		content
    animation {
      localFile {
        absolutePath
        base
        publicURL
        relativePath
      }
    }
		graphic {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            quality: 80
            formats: [AUTO, WEBP]
            outputPixelDensities: [1.5, 2]
            width: 972
            height: 567
            transformOptions: {cropFocus: NORTH}
					)
				}
			}
		}
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_List {
		
		sectionLabel
		heading
		spaceBackground
		content
		listItems {
			text
		}
	
		}
	 
 
		... on WpPage_Components_Components_MarqueeList {
		
    sandwichedContent
		tag
		heading
		content
		listItems {
			listItem
		}
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_NumberedList {
		
    tag
		sectionHeading
    content
		numberedItems {
			itemHeading
			itemContent
		}
	
		}
	 
 
		... on WpPage_Components_Components_ProjectGrid {
		
		sectionLabel
		heading
	
		}
	 
 
		... on WpPage_Components_Components_ProjectSlider {
		
		sectionLabel
		heading
		content
		projects {
			... on WpProject {
        title
				uri
				projectDetails {
					platform
					whatWeDid
					screenshots {
						fullPageDesktop {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										placeholder: TRACED_SVG
										quality: 80
										formats: [AUTO, WEBP]
										outputPixelDensities: [1.5, 2]
										width: 972
										height: 552
										transformOptions: {cropFocus: NORTH}
					        )
								}
							}
						}
						primaryMobile {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
				}
			}
		}
	
		}
	 
 
		... on WpPage_Components_Components_RepeatingCtaBlocks {
		
		ctaBlocks {
			button {
				target
				title
				url
			}
			heading
			graphic {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
              layout: CONSTRAINED
              placeholder: TRACED_SVG
              quality: 100
              formats: [AUTO, WEBP]
              outputPixelDensities: [1.5, 2]
              width: 200
						)
					}
				}
			}
			content
		}
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_ScrollList {
		
		sectionLabel
		heading
		content
    animation {
      localFile {
        absolutePath
        base
        publicURL
        relativePath
      }
    }
		scrollListItems {
			itemTitle
			itemIcon {
				localFile {
					childImageSharp {
						gatsbyImageData(
              layout: FIXED
              placeholder: TRACED_SVG
              quality: 100
              formats: [AUTO, WEBP]
              outputPixelDensities: [1.5, 2]
              width: 40
              height: 40
						)
					}
          publicURL
          extension
				}
			}
			itemContent
		}
	
		}
	 
 
		... on WpPage_Components_Components_SubscribeBar {
		
		sectionLabel
		heading
	
		}
	 
 
		... on WpPage_Components_Components_SupportForm {
		
		heading
	
		}
	 
 
		... on WpPage_Components_Components_TeamGrid {
		
		sectionLabel
		heading
    teamMembers {
      ... on WpTeamMember {
        title
        uri
        id
        teamMemberDetails {
          firstName
          title
          beans {
            type
            archiveImage {
              gatsbyImage(
                width: 270
                quality: 80
                outputPixelDensities: [1.5, 2]
                formats: [AUTO, WEBP]
                fit: CONTAIN
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	 
 
		... on WpPage_Components_Components_Testimonials {
		
		heading
    selectedTestimonials {
      ... on WpTestimonial {
        title
        content
      }
    }
    background {
      hasBackground
      squiggleTop
      squiggleBottom
      lastComponent
    }
	
		}
	
		}
	}
  
		}
	  }
	`
  
